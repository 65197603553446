/* #1a3a46, #386857, #738564, #e89d56, #CD541D and #922637 */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'MuseoModerno', cursive;
  overflow-x: hidden;
}


svg * {
  transform-origin: 50%
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes rotate_back {
  100% {
    transform: rotate(-360deg)
  }
}

@keyframes sway {
  100% {
    transform: translateX(10%)
  }
}

@keyframes drop {
  100% {
    transform: translateY(45%)
  }
}

@keyframes rise {
  100% {
    transform: translateY(-20%)
  }
}

@keyframes hero_one {
  100% {
    transform: rotate(360deg) translate(17.5%, 17.5%)
  }
}

@keyframes hero_two {
  100% {
    transform: rotate(360deg) translate(17.5%, -17.5%)
  }
}

@keyframes hero_three {
  100% {
    transform: rotate(360deg) translate(-17.5%, 17.5%)
  }
}

@keyframes hero_four {
  100% {
    transform: rotate(360deg) translate(-17.5%, -17.5%)
  }
}

@keyframes blob_one {
  100% {
    transform: rotate(-360deg) translate(-12.5%, -12.5%)
  }
}

@keyframes blob_two {
  100% {
    transform: rotate(-360deg) translate(12.5%, -12.5%)
  }
}

@keyframes blob_three {
  100% {
    transform: rotate(-360deg) translate(-12.5%, 12.5%)
  }
}

@keyframes blob_four {
  100% {
    transform: rotate(-360deg) translate(-12.5%, -12.5%)
  }
}